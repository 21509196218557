* {
  box-sizing: border-box;

  --brand-red: #EE2C53;
  --brand-orange: #E69F00FF;
  --brand-yellow: #FFE156;
  --brand-green: #C4E44C;
  --brand-green2: #60B33C;
  --brand-ltblue: #2AA2B3;
  --brand-dkblue: #02627C;
  --brand-purple: #552A99;/*#4D2586*/;

  --begich: var(--brand-yellow);
  --palin: var(--brand-red);
  --peltola: var(--brand-ltblue);

  --montroll: var(--brand-purple);
  --wright: var(--brand-red);
  --kiss: var(--brand-green);

  --gordon: var(--brand-green);
  --arab: var(--brand-purple);
  --worlobah: var(--brand-orange);

  --bunney: var(--brand-purple);
  --goings: var(--brand-orange);
  --mccarthy: var(--brand-ltblue);

  --engardio: var(--brand-orange);
  --melgar: var(--brand-purple);
  --nguyen: var(--brand-ltblue);

  --hutchinson: var(--brand-dkblue);
  --manigo: var(--brand-purple);
  --resnick: var(--brand-green);

  --wojciechowski: var(--brand-dkblue);
  --kovash: var(--brand-orange);
  --taylor: var(--brand-purple);

  --wiley: var(--brand-purple);
  --garcia: var(--brand-dkblue);
  --adams: var(--brand-ltblue);

  --behrendt: var(--brand-red);
  --torre: var(--brand-green);
  --johnson: var(--brand-purple);

  --pieGray: #111111;
  --voterGray: darkgray;
  font-family: 'Montserrat';
  font-weight: bold;
}

.campaignColors {
  --begich: #FFB612;
  --palin: #F11E01;
  --peltola: #C1E8F5;

  --montroll: #3333FF;
  --wright: #E81B23;
  --kiss: #C64C4B;

  --gordon: #17AA5C;
  --arab: #5C068B;
  --worlobah: #ED8C3B;

  --bunney: purple;
  --goings: orange;
  --mccarthy: cyan;

  --engardio: #F8641A;
  --melgar: #B51E83;
  --nguyen: #00AAA4;

  --hutchinson: #122110;
  --manigo: #4D0EC1;
  --resnick: #238C23;

  --wojciechowski: #0C152D;
  --kovash: #C66230;
  --taylor: #C0AF9B;

  --wiley: #9C64AA;
  --garcia: #233656;
  --adams: #00AFDF;

  --behrendt: #AD0006;
  --torre: #39B449;
  --johnson: #3D3A89;
}

body, #root{
  width: 100vw;
  height: 100vh;
  margin: 0;
  background: black;
}

.Nav{
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;

  background: #202020;
  position: absolute;
  width: 100%;
  top: 30px;
  overflow: hidden;
}

.Nav.USA{
  position: relative;
}

.NavButton{
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  height: 50px;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 5px;

  text-decoration: none;
}

.NavButton h4{
  color: white;
}

.NavButton:hover{
  background: #575757;
}

.NavButton img{
  height: 100%;
}

.app{
  display: flex;
  flex-direction: column;

  position: relative;
  overflow: hidden;
  margin: auto;

  width: 100%;
  height: 100%;
}

.columns{
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;

  position: relative;
  overflow: hidden;
  margin: auto;

  padding: 5vh;
  width: 100%;
  height: 100%;
}

.simulation{
  position: relative;
  width: min(90vh, 60vw);
  height: min(90vh, 60vw);
  margin-top: 60px; /* Keep the nav bar from cutting off the animation */
  background: black;
  border: 0;
}

.simPanel{
  display: flex;
  flex-direction: column;
}

/**** EXPLAINER ****/
.explainer{
  position: relative;
  flex-grow: 1;
  text-align: left;

  height: 100%;
  width: calc(100% - min(90vh, 60vw));

  padding-left: 5%;

  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  overflow-x: hidden;
  overflow-y: scroll;
}

.explainerTopPadding{
  height: 20vh;
  min-height: 20vh;
}

.explainerBottomPadding{
  height: 60vh;
  min-height: 60vh;
}

.explainer h1, h2, h3, p, ol, ul, li, i, label{
  color: white;
}

.explainer p, .explainer i, ul, li{
  font-size: 1.5rem;
}

.explainer ol{
  font-size: 1.5rem;
}

.explainerItem{
  transition: transform .2s ease 0s, opacity .2s ease 0s;
}

.explainerInner{
  height: auto;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.explainerFocused {
  transform: translate(-5%, 0);
  opacity: 1;
}

.explainerUnfocused {
  transform: translate(0, 0);
  opacity: .7;
}

/***** OBJECTS ***/
.Pie{
  border-radius: 100%;
  background: var(--pieGray);
  border-color: darkgray;
  border-block: solid;
  transition: opacity .5s ease;
}

.Pie.objectVisible{
  /*opacity: .1;*/
  opacity: 1;
}

.VoterCount{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.object{
  position: absolute;
  /* // transitioning opacity causes jittering for some reason
  transition-property: 'opacity';
  transition-duration: 1s;
  transition-timing-function: ease;
  */
}

.ImageObject{
  background-position: center;
  background-repeat: no-repeat;
  border-width: 0;
  transition-property: opacity;
  transition-duration: .5s;
}

.Video{
  transition-property: opacity;
  transition-duration: .5s;
}

.Nav{
  z-index: 20;
}

.objectVisible.objectFocused{
  z-index: 2;
}

.DarkenLayer{
  z-index: 1;
  background: black;
  transition: opacity .5s ease;
}

.DarkenLayer.objectVisible{
  opacity: .7;
}

.objectVisible.objectUnfocused{
  z-index: 0;
}

.objectInvisible{
  opacity: 0;
}

.ImageObject.objectInvisible{
  transition-delay: 0s;
}

.ImageObject.objectVisible{
  /*transition-delay: .5s;*/
}

.Voter{
  border-radius: 50%;
  border-color: black;
  border-block: solid;
}

.stable{
  background: blue;
}

.VoterCamp{
  border-color: #00000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.VoterCamp p{
  color: white;
}

.CandidateInner{
  border-radius: 100%;
  border-block: solid;
  background-position: center;
  background-size: cover;
}


.Candidate{
  transition: opacity 1s linear;
}
.Candidate.candidate0{
  transition-delay: 0s;
}

.Candidate.candidate1{
  transition-delay: .25s;
}

.Candidate.candidate2{
  transition-delay: .5s;
}

img{
  margin: auto;
}

a, a:visited{
  color: var(--brand-green2);
  text-decoration: none;
}

a:hover{
  text-decoration: underline;
}

/* Scrollbar */

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  opacity: 0;
  margin-top: 50px;
  margin-bottom: 30px;
}

::-webkit-scrollbar-thumb {
  min-height: 70px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 5px;
}

/* SELECTOR */
.selectors{
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
}

.selectorButtons{
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: space-between;
}

.selectorButtons button{
  height: 50%;
  min-height: 20px;
  margin: auto;
}

.selectorsSwapped{
  flex-direction: column-reverse;
}

.selectorPanel{
  display: flex;
  flex-direction: row;
  justify-content: top;
  gap: 40px;
  margin-top: 50px;
  flex-wrap: wrap;
}

.selectorPanel button{
  width: 120px;
}

.nav{
  margin: 10px;
}

.electionSelector, .failureSelctor{
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.selectorContainer{
  background: #111111;
  border-radius: 20px;
  width: 110%;
  padding: 10%;
  transform: translate(-10%,0);
}

.Candidate.winner .CandidateInner{
  animation-name: win;
  animation-duration: 1.5s;
}

@keyframes win{
  20%{
    transform: scale(1.1, 1.1);
  }

  60%{
    transform: scale(.95, .95);
  }

  100%{
    transform: scale(1, 1);
  }
}

/* bar chart */
.bars line{
  display: none;
}

.bars tspan{
  fill: white !important;
}

@media(max-width: 900px){
  .Nav{
    gap: 5px;
  }
  .NavButton{
    height: 35px;
  }
  .NavButton h4{
    font-size: .7rem;
  }


  .explainerPadding{
    height: 40vh;
  }

  .CandidateInner h3{
    font-size: 20rem;
  }

  .columns{
    flex-direction: column;
    padding: 1vh;
  }

  .simulation{
    width: 60vw;
    height: 60vw;
  }

  .explainer{
    height: calc(100% - 60vw);
    width: 100%;
    scrollbar-width: 0;
  }

  ::-webkit-scrollbar-thumb {
    display: none;
  }

  .explainer h1 {
    font-size: 1.3rem;
  }

  .explainer h2, .explainer p, .explainer i, .explainer li {
    font-size: 1rem;
  }

  .explainerTopPadding{
    height: 30px;
    min-height: 30px;
  }

  .explainerBottomPadding{
    height: 150px;
    min-height: 150px;
  }

  .Voter{

  }

  .app{
    overflow: visible;
  }

  * {
    overflow-x: visible;
  }

  .selectorContainer{
    border-radius: 20px;
    width: 100%;
    transform: revert;
    padding: 0;
  }

  .explainer{
    padding-left: 5%;
  }

  .explainerInner{
    padding-left: 50px;
  }

}
